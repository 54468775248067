var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0"},[(this.pacUser)?_c('v-btn',{staticClass:"text-none",staticStyle:{"margin-right":"10px","font-size":"11pt"},attrs:{"tile":""},on:{"click":function($event){return _vm.handleClick({ url: _vm.pacUrl, target: '_blank' })}}},[_c('iconify-icon',{staticClass:"toggle-icon",attrs:{"icon":"solar:ticket-outline"}}),_vm._v("   Paciolan ")],1):_vm._e(),(this.etrieveUser)?_c('v-btn',{staticClass:"text-none",staticStyle:{"margin-right":"10px","font-size":"11pt"},attrs:{"tile":""},on:{"click":function($event){return _vm.handleClick({ url: _vm.etrieveUrl, target: '_blank' })}}},[_c('iconify-icon',{staticClass:"toggle-icon",attrs:{"icon":"solar:documents-outline"}}),_vm._v("   Etrieve ")],1):_vm._e(),(_vm.condense)?_c('v-menu',{attrs:{"left":"","nudge-bottom":"45","min-width":"230","content-class":"activity-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),onMenu),[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Actions")])],1)]}}],null,false,1643406497)},[_c('v-list',{staticStyle:{"background-color":"var(--lu-primary)","width":"fit-content"}},_vm._l((_vm.links),function(link,index){return _c('div',{key:index},[_c('v-divider',{staticStyle:{"opacity":"0.3"},attrs:{"color":"white"}}),_c('v-list-item',{staticClass:"mt-1",staticStyle:{"margin-top":"-1rem"},on:{"click":function($event){return _vm.handleClick(link)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(_vm._s(link.icon))]),_c('div',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(link.label))])],1)],1)}),0)],1):(_vm.links.find(function (l) { return l.show === true; }))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('iconify-icon',{attrs:{"icon":"solar:menu-dots-outline"}})],1)]}}])},_vm._l((_vm.links),function(link,index){return _c('v-list',{directives:[{name:"show",rawName:"v-show",value:(link.show),expression:"link.show"}],key:index},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleClick(link)}}},[_c('v-list-item-title',[_vm._v(_vm._s(link.label))])],1)],1)}),1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"top":"","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"var(--lu-light-gray)","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Contact Threatened Legal Action ")]),_c('v-card-text',[_vm._v("Are you sure you want to mark contact as 'Threatened Legal Action'?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"var(--lu-red)","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"var(--lu-secondary)","loading":_vm.loadingLAT,"text":""},on:{"click":function($event){return _vm.legalActionThreatenedApproved()}}},[_vm._v(" Agree ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }