import Vue from 'vue';
import VueRouter from 'vue-router';
import globals from '../globals';
// import MassCommunication from '@/views/pages/MassCommunication';
import Eloqua from '@/views/pages/Eloqua';
import { BannerCommunicationPreferences } from 'aether-bannercommunications-vue2';
import { CampusSwitch, Declaration, Feedback, ManualRegistration } from 'aether-luoworkbench-forms-vue2';
import Login from "@/layouts/Login.vue";
import { Search } from 'aether-student-lookup-vue2';
import { ContactSearchLanding } from 'aether-contact-search-vue2'
import SummaryPageLanding from "@/layouts/components/landingPages/SummaryPageLanding.vue";
import WorkbenchLanding from "@/layouts/components/landingPages/WorkbenchLanding.vue";
import NotFound from "@/layouts/NotFound.vue";
import Error from "@/layouts/Error.vue";
import { TabContainerRoutes } from "aether-tabcontainers-vue2";
import { ReportRoutes } from "aether-reports-vue2";
import { AdminRoutes } from "aether-admin-vue2";
import { PinnedRecentDashboard } from "aether-user-nav-actions-vue2";
import { MassCommsAll } from "aether-mass-comms-vue2";
import { OpportunityRoutes } from 'aether-opportunities-vue2';
import { Dashboards } from 'aether-dashboards-vue2';
import { RecordSearchRoutes } from 'aether-record-search-vue2';
import { QuickContact } from 'aether-quickcreate-vue2';
import { ComponentExampleRoutes } from 'aether-basic-components-vue2'
import { TripsLandingPage } from 'aether-trips-vue2'
import * as serviceLibrary from 'aether-service-library';

Vue.use(VueRouter)

let externalRoutes = [TabContainerRoutes, ReportRoutes, AdminRoutes, OpportunityRoutes, RecordSearchRoutes, ComponentExampleRoutes]
let userVisibility = localStorage.getItem('userVisibility');
let visibility = userVisibility ? userVisibility.split(',') : []

//only populated with routes the user has access to
const routes = [
  {
    path: '/',
    beforeEnter: async (to, from, next) => {
      let appName = localStorage.getItem('appName')
      if (appName) {
        next({
          path: `/${appName.replace(/\s+/g, '')}/search`
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: "Login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      document.title = "RELM | Login";
      next()
    }
  },
  {
    path: '/error',
    name: "Error",
    component: Error,
    beforeEnter: async (to, from, next) => {
      document.title = "Error";
      next()
    }
  },
  //default / not found
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    props: true,
    beforeEnter: (to, from, next) => {
      console.log("route not found")
      checkOtherRoutes(to, from, next)
    }
  }
]

let appRoutes = [
  { path: '/:appName/:luid/workbench', name: 'Workbench', appName: 'LUO, LUOA', component: WorkbenchLanding },
  { path: '/:appName/declarations', name: 'Declarations', appName: 'LUO', component: Declaration },
  { path: '/:appName/manual_registrations', name: 'Manual Registrations', appName: 'LUO', component: ManualRegistration },
  { path: '/:appName/campus_switches', name: 'Campus Switches', appName: 'LUO', component: CampusSwitch },
  { path: '/:appName/feedback', name: 'Feedback', appName: 'LUO', component: Feedback },
  { path: `/:appName/:luid/eloqua`, name: `Eloqua`, appName: 'uDev', component: Eloqua },
  { path: `/:appName/dashboards`, name: `Dashboards`, appName: 'uDev', component: Dashboards },
  { path: `/:appName/:luid/massComms`, appName: 'any', name: `Mass Communications`, component: MassCommsAll },
  { path: `/:appName/:luid/bcp`, appName: 'any', name: `Comm Preferences`, component: BannerCommunicationPreferences },
  { path: `/:appName/new-contact`, appName: 'any', name: `Create New Contact`, component: QuickContact, beforeEnter: async (to, from, next) => { await serviceLibrary.handleRouting(to, from, next) } },
  { path: `/:appName/:luid/notes-and-activities`, appName: 'any', name: `Notes & Activities`, component: () => import('@/views/pages/NotesActivities.vue') },
  { path: `/:appName/:luid/details`, appName: 'any', name: `details`, component: () => import('@/views/pages/Details.vue') },
  {
    path: `/:appName/:luid/summary`, appName: 'any', name: `Summary`, component: SummaryPageLanding, props: route => ({
      luid: route.params.luid,
      modalData: route.params.modalData ? route.params.modalData : null
    })
  },
  { path: `/:appName/pinnedRecentDashboard`, appName: 'any', name: `Pinned/Recent Dashboard`, component: PinnedRecentDashboard },
  { path: `/:appName/search`, appName: 'any', name: `Search`, component: Search, props: { windowLocation: '_parent' } },
  { path: `/:appName/contacts`, appName: 'Donor Engagement', name: `Contact Search`, component: ContactSearchLanding, props: { windowLocation: '_parent' } },
  { path: `/:appName/trips`, appName: 'uDev', name: `Trips Landing`, component: TripsLandingPage },
  { path: `/:appName/trips/:id`, appName: 'uDev', name: `Trip Details`, component: TripsLandingPage },
]

for (const route of appRoutes) {
  //making sure that the current user has access to the app / at least one of the apps the page is under
  if (isVisible(visibility, route.appName)) {
    //beforeEnter runs this method immediately on route change, before navigating to the page
    route.beforeEnter = async (to, from, next) => {
      await verifyAndReroute(route, to, from, next)
    }
    routes.push(route);
  }
}

//looping through the external routes array to get the array of routes from each external library
for (const routeList of externalRoutes) {
  //looping through each route in the library's array of routes
  for (const route of routeList) {

    //making sure that the current user has access to the app / at least one of the apps the page is under
    if (isVisible(visibility, route.appName)) {
      //beforeEnter runs this method immediately on route change, before navigating to the page
      route.beforeEnter = async (to, from, next) => {
        await verifyAndReroute(route, to, from, next)
      }
      routes.push(route);
    }
  }
}

function checkOtherRoutes(to, from, next) {
  let url = to.path
  let luid = url.match(/L\d{8}/i)
  let path = to.path.substring(to.path.lastIndexOf('/'));
  let otherRoutes = routes.find(r => r.path.toLowerCase().endsWith(path.toLowerCase()))

  try {
    if (otherRoutes) {
      for (const app of visibility) {
        let visibleRoute;
        if (otherRoutes.length > 1) {
          visibleRoute = otherRoutes.find(or => or.path.includes(app))
        } else if (otherRoutes.path.includes(app)) {
          visibleRoute = otherRoutes
        }

        if (visibleRoute) {
          if (visibleRoute.path.includes(':luid') && luid) {
            visibleRoute.path = visibleRoute.path.replace(':luid', luid[0])
          }

          next({
            path: visibleRoute.path
          })
          break;
        }
      }
    }
  } catch (err) {
    console.log(err)
  }

  next();
}

async function verifyAndReroute(route, to, from, next) {
  try {
    //making sure that the app specified in the url is a valid app
    if (verifyApp(route, to.params)) {
      document.title = "RELM | " + to.name;
      sessionStorage.setItem('docTitle', "RELM | " + route.name)
      //this method handles any additional logic that may come from a route change (getting contact, changing app, etc)
      await serviceLibrary.handleRouting(to, from, next)
    } else {
      //if app name is not valid for whatever, reason, this gets a page to redirect to
      let redirect = handleNotFound(route, to)
      next(redirect)
    }
  } catch (err) {
    console.log(err)
  }
}

function isVisible(visibility, appName) {
  try {
    switch (appName) {
      case 'any':
        //if page can be viewed in any (valid) app, return true as any user will always have access to at least one app
        return true;
      case 'uDev':
        //if page can only be viewed by a uDev app, loop through uDev apps. if user has access to at least one, return true
        for (const app of globals.uDevApps) {
          if (visibility.find(v => v === app)) {
            return true
          }
        }
        return false;
      default:
        //getting all specified apps to loop through
        let apps = appName.split(', ')

        //loop through specified apps. if user has access to at least one, return true
        for (const app of apps) {
          if (app === 'uDev') {
            for (const app of globals.uDevApps) {
              if (visibility.find(v => v === app)) {
                return true
              }
            }
            return false;
          } else if (visibility.find(v => v === app)) {
            return true
          }
        }
        return false;
    }
  } catch (err) {
    console.log(err)
  }
}

function verifyApp(route, params) {
  try {
    //getting app name from url
    let appName = params.appName

    //check to see if app name is in list of apps for route
    let validApp = route.appName.split(',').find(a =>
      a === appName
      || a.replace(' ', '') === appName
    );

    //if app not explicitly in list, check for groups
    if (!validApp) {
      if (route.appName === 'any') {
        //if route allows any app, just make sure the app name entered is a valid app
        validApp = globals.allApps.find(a => simpleString(a) === simpleString(appName))
      } else if (route.appName.includes('uDev')) {
        //if route allows any uDev app, make sure the app name entered is in the uDev app array
        validApp = globals.uDevApps.find(a => simpleString(a) === simpleString(appName))
      }
    }

    if (validApp) {
      //if the app name is a valid app, make sure the user has access to view said app
      return visibility.find(v => simpleString(v) === simpleString(appName))
    } else {
      //if no matching app / app name is not valid, return false
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

function simpleString(string) {
  return string.toLowerCase().replace(' ', '')
}

function handleNotFound(route, path) {
  let appArray;
  if (route.appName === 'any') {
    appArray = globals.allApps;
  } else {
    appArray = route.appName.split(', ')
  }

  let uDevIndex = appArray.findIndex(a => a === 'uDev');
  if (uDevIndex > -1) {
    appArray.splice(uDevIndex, 1, ...globals.uDevApps)
  }

  //if other app options, loop through to see if user has access to any of the other options
  if (appArray.length > 1) {
    for (const app of visibility) {
      let visibleApp = appArray.find(a => a === app)
      if (visibleApp) {
        document.title = "RELM | " + path.name;
        sessionStorage.setItem('docTitle', "RELM | " + path.name)

        //use the same route, and pass in the new app name
        return { name: path.name, params: { appName: visibleApp.replace(' ', ''), luid: path.params.luid } }
      }
    }
  }

  //if no other app option, or user didn't have access to any other option, go to the "not found" page
  sessionStorage.setItem('pathNotFound', path.path)
  return { name: 'Not Found' }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
