import httpService from './http.service';

export class CustomConfigService {
    async getEtrieveUrl() {
        try {
            let apiRes = await httpService.getFromAetherInfoApi('GET_CONFIGURATION_SETTING_VALUE', 'setting', 'Soft Docs Url');

            return apiRes.data + `${sessionStorage.getItem('libertyId')})`;
        }
        catch (error) {
            // alert(error)
            console.log(error)
        }
    };

    async getSelfCertUrl() {
        try {
            let apiRes = await httpService.getFromAetherInfoApi('GET_CONFIGURATION_SETTING_VALUE', 'setting', 'Self Cert Url');

            return apiRes.data;
        }
        catch (error) {
            // alert(error)
            console.log(error)
        }
    };

    async setBannerDown() {
        try {
            let apiRes = await httpService.getFromAetherInfoApi('GET_CONFIGURATION_SETTING_VALUE', 'setting', 'Is Banner Down');

            window.localStorage.setItem('isbannerdown', apiRes.data === 'Y' ? 'true' : 'false');
            return;
        }
        catch (error) {
            console.log(error)
        }
    };
}

export default new CustomConfigService();